import { BetSlipState } from '@arland-bmnext/api-data'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import React, { useCallback, useEffect } from 'react'
import { useSportsBettingSettings } from '../../lib/content'
import { isLoggedInCustomer, useOpenUserBets, useUser } from '../../lib/user'
import { BetsListItem, BetsListItemSkeleton } from '../account/bets/BetsListItem'
import BaseWidget, { BaseWidgetProps } from './BaseWidget'

import { Swiper, SwiperSlide } from 'swiper/react'

type OpenBetsWidgetProps = BaseWidgetProps & {
  orientation?: 'horizontal' | 'vertical'
  max_bets?: number
}

const OpenBetsWidget = (props: OpenBetsWidgetProps) => {
  const { t } = useTranslation('bets')
  const sportsBettingSettings = useSportsBettingSettings()
  const { openBets, mutateOpenBets, isValidating } = useOpenUserBets(
    sportsBettingSettings?.cashoutEnabled,
    props.max_bets,
    true,
  )
  const showSkeleton = isValidating && openBets == null
  const { user } = useUser()

  useEffect(() => {
    mutateOpenBets()
  }, [user?.id])

  const onBetCashedOut = useCallback(
    (betId: number) => {
      const bets = [...openBets]
      if (bets?.length > 0) {
        bets.find((bet) => bet.userBet.id === betId).userBet.state = BetSlipState.CashedOut
        setTimeout(() => mutateOpenBets(bets), 3000)
      }
    },
    [openBets],
  )

  const ShowAllBets = () => {
    return (
      <Link href="/account/bets" legacyBehavior>
        <span className="text-primary text-sm font-medium cursor-pointer hover:underline">
          {t('OpenBetsWidget.showAll')}
        </span>
      </Link>
    )
  }

  return (
    <>
      {(showSkeleton || openBets?.length > 0) && isLoggedInCustomer(user) && (
        <BaseWidget
          className="open-bets-widget"
          {...props}
          actionElement={<ShowAllBets />}
          title={props.title ?? t('OpenBetsWidget.title')}
        >
          {(props.orientation === 'horizontal' || props.orientation == null) && (
            <div className="open-bets-widget-horizontal flex w-full overflow-hidden relative">
              {openBets?.length > 0 && (
                <Swiper className="open-bets-widget-horizontal-swiper w-full" slidesPerView={'auto'} grabCursor>
                  {openBets?.map((bet) => (
                    <SwiperSlide
                      key={bet.userBet.id}
                      className={`open-bets-widget-horizontal-swiper-slide w-[calc(100%-32px)] md:w-[calc(100%-40px)] max-w-[400px] md:first:ml-4 ml-2 last:mr-4`}
                    >
                      <BetsListItem
                        bet={bet.userBet}
                        onBetCashedOut={onBetCashedOut}
                        cashoutCalculation={sportsBettingSettings.cashoutEnabled ? bet.cashoutCalculation : null}
                        hideBetId
                        key={bet.userBet.id}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}

              {showSkeleton && (
                <div className="flex items-start px-2 md:px-4 space-x-2 w-full overflow-hidden">
                  {[...Array(5)].map((_, index) => (
                    <BetsListItemSkeleton width="w-[calc(100%-32px)] max-w-[400px]" key={index} />
                  ))}
                </div>
              )}
            </div>
          )}

          {props.orientation === 'vertical' && (
            <div className="open-bets-widget-vertical flex flex-col space-y-2">
              {openBets?.map((bet) => (
                <BetsListItem
                  bet={bet.userBet}
                  onBetCashedOut={() => onBetCashedOut(bet.userBet.id)}
                  cashoutCalculation={sportsBettingSettings.cashoutEnabled ? bet.cashoutCalculation : null}
                  hideBetId
                  key={bet.userBet.id}
                />
              ))}

              {showSkeleton && [...Array(5)].map((_, index) => <BetsListItemSkeleton width="w-full" key={index} />)}
            </div>
          )}
        </BaseWidget>
      )}
    </>
  )
}

export default OpenBetsWidget
