import { CasinoPlayDTO, CmsCompetitionDTO } from '@arland-bmnext/webapps-api-data'
import useTranslation from 'next-translate/useTranslation'
import { useMemo } from 'react'
import useCurrentLanguage from '../hooks/useCurrentLanguage'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { moveArrayElement } from '../util/array'
import { buildCasinoCategoriesViewModel, CasinoCategoryViewModel } from '../util/gaming'
import { isNullOrEmpty } from '../util/string'
import { AxiosClient } from './axiosclient'
import { useCasinoCategories } from './content'

export const playCasinoGame = async (
  gameId: number,
  playmode: 'real' | 'demo',
  variant: 'desktop' | 'mobile',
): Promise<CasinoPlayDTO> => {
  let query = '?'
  query += 'gameId=' + gameId
  query += '&playmode=' + playmode
  query += '&variant=' + variant

  return await AxiosClient.get<CasinoPlayDTO>(`/api/gaming/play-casino-game${query}`)
}

export const playLiveCasinoGame = async (
  gameId: number,
  playmode: 'real' | 'demo',
  variant: 'desktop' | 'mobile',
  productIdentifier: string,
): Promise<CasinoPlayDTO> => {
  let query = '?'
  query += 'gameId=' + gameId
  query += '&playmode=' + playmode
  query += '&variant=' + variant
  query += '&productIdentifier=' + productIdentifier

  return await AxiosClient.get<CasinoPlayDTO>(`/api/gaming/play-live-casino-game${query}`)
}

export const useRecentlyPlayedCasinoGames = (): [
  recentlyPlayedGames: number[],
  addToRecentlyPlayedGames: (gameId: number) => void,
] => {
  const [recentlyPlayedGames, setRecentlyPlayedGames] = useLocalStorage<number[]>('recent-casino-games', [])

  const addToRecentlyPlayedGames = (gameId: number): void => {
    const _recentlyPlayedGames = [...recentlyPlayedGames]
    if (_recentlyPlayedGames.includes(gameId)) {
      moveArrayElement(_recentlyPlayedGames, _recentlyPlayedGames.indexOf(gameId), 0)
    } else {
      _recentlyPlayedGames.unshift(gameId)
    }
    setRecentlyPlayedGames(_recentlyPlayedGames)
  }

  return [recentlyPlayedGames, addToRecentlyPlayedGames]
}

export const useCasinoGameViewModel = (): [
  availableCasinoGames: CmsCompetitionDTO[],
  casinoCategoriesViewModel: CasinoCategoryViewModel[],
] => {
  const language = useCurrentLanguage()
  const { t } = useTranslation('casino')

  const casinoCategories = useCasinoCategories(language?.id)
  const [recentlyPlayedGames] = useRecentlyPlayedCasinoGames()

  const availableCasinoGames = useMemo<CmsCompetitionDTO[]>(() => {
    const games = {}

    if (isNullOrEmpty(casinoCategories)) return []

    casinoCategories?.forEach((cc) =>
      cc.competitions?.forEach((c) => {
        if (games[c.id] == null) {
          c.name = c.name?.replace('\r\n', '')
          games[c.id] = c
        }
      }),
    )

    return Object.values(games)
  }, [casinoCategories])

  const casinoCategoriesViewModel = useMemo<CasinoCategoryViewModel[]>(() => {
    if (language)
      return buildCasinoCategoriesViewModel(t, casinoCategories, availableCasinoGames, recentlyPlayedGames, language.id)
  }, [availableCasinoGames, recentlyPlayedGames])

  return [availableCasinoGames, casinoCategoriesViewModel]
}
