
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useEffect } from 'react';
import View from '../../components/layout/View';
import { withPageSession } from '../../lib/session';
import OpenBetsWidget from '../../components/widgets/OpenBetsWidget';
import { CmsPageIdentifier, getCmsPage, useSportsBettingSettings } from '../../lib/content';
import { NextPageWithLayout } from '../_app';
import { PageFilterType } from '../../filter/types';
import SportLeagueBrowser from '../../components/odds/slb/SportLeagueBrowser';
import { getPropsFromContentFields, getWidgetByUniqueIdentifier, getWidgetFromCmsPage, NextWoqWidget, } from '../../util/content';
import { isAnonymousUser, useUser, useUserAccounts } from '../../lib/user';
import Betslip from '../../components/odds/betslip/Betslip';
import CasinoGamesWidget from '../../components/widgets/CasinoGamesWidget';
import { useBetslipContext } from '../../context/betslip.context';
import { buildBetSlipDataFromSharedBet, getSharedBet } from '../../util/shared-bets';
import { useRouter } from 'next/router';
import { useLayoutContext } from '../../context/layout.context';
import type { DisplayPredicate } from '@bmnext-cms/types/content/display-filter';
import { evalDisplayPredicates } from '../../util/display-predicate';
import RankingWidget from '../../components/widgets/RankingWidget';
const Home: NextPageWithLayout = (props: any) => {
    const { user } = useUser();
    const { mainAccount } = useUserAccounts();
    const router = useRouter();
    const betslipContext = useBetslipContext();
    const sportsBettingSettings = useSportsBettingSettings();
    const layoutContext = useLayoutContext();
    const isDesktop = layoutContext.currentBreakPoint === 'desktop';
    useEffect(() => {
        if (sportsBettingSettings == null || !sportsBettingSettings.betSharingEnabled || !betslipContext.initialized)
            return;
        if (router.query.referenceId && (props.sharedBet === null || props.sharedBetItems?.length === 0))
            return betslipContext.shareBetWarning();
        const betslipData = buildBetSlipDataFromSharedBet(props.sharedBet, props.sharedBetItems);
        if (betslipData) {
            betslipContext.restoreBetSlip({ referenceId: router.query.referenceId as string, data: betslipData });
        }
    }, [props.sharedBet, props.sharedBetItems, sportsBettingSettings, betslipContext.initialized]);
    return (<div className="front-page-inner flex flex-col space-y-6 w-full py-4">
      {props?.cmsPage?.referencedWidgets?.map((referencedWidget, index) => {
            const referencedContent = referencedWidget.referencedContent;
            const widgetIdentifier = referencedContent?.type?.uniqueId;
            const Widget = getWidgetByUniqueIdentifier(widgetIdentifier);
            const widgetProps = getPropsFromContentFields(referencedContent);
            const displayPredicates: DisplayPredicate[] = referencedContent.displayFilter
                ? JSON.parse(referencedContent.displayFilter)
                : null;
            const displayWidget = evalDisplayPredicates(displayPredicates, user, mainAccount);
            if (Widget == null ||
                !displayWidget ||
                (widgetIdentifier === NextWoqWidget.OpenBetsWidget && (isAnonymousUser(user) || isDesktop)) ||
                (widgetIdentifier === NextWoqWidget.CasinoGamesWidget && isDesktop) ||
                (widgetIdentifier === NextWoqWidget.RankingWidget && isDesktop)) {
                return <React.Fragment key={index}></React.Fragment>;
            }
            return <Widget {...widgetProps} addPadding index={index} key={index}/>;
        })}
    </div>);
};
Home.getLayout = function getLayout(_t, pageProps, page) {
    const casinoGamesWidget = getWidgetFromCmsPage(pageProps?.cmsPage, NextWoqWidget.CasinoGamesWidget);
    const openBetsWidget = getWidgetFromCmsPage(pageProps?.cmsPage, NextWoqWidget.OpenBetsWidget);
    const rankingWidget = getWidgetFromCmsPage(pageProps?.cmsPage, NextWoqWidget.RankingWidget);
    const widgets = [];
    if (openBetsWidget?.widget)
        widgets.push(<OpenBetsWidget {...openBetsWidget?.widgetProps} orientation="vertical"/>);
    if (casinoGamesWidget?.widget)
        widgets.push(<CasinoGamesWidget {...casinoGamesWidget?.widgetProps}/>);
    if (rankingWidget?.widget)
        widgets.push(<RankingWidget {...rankingWidget?.widgetProps}/>);
    return (<View pageId="front-page" headerProps={{ addStakeSelector: true, addBonusBanner: true }} leftWidgets={[<SportLeagueBrowser mode={'slb'} redirectToSearchPage/>]} rightWidgets={[...widgets, <Betslip mode="desktop" dropShadow/>]}>
      {page}
    </View>);
};
const getServerSideProps = withPageSession(async ({ query, session, req }, _pageFilterResult, pageData) => {
    const { sharedBet, sharedBetItems } = await getSharedBet(req, session, query);
    const landingPage = await getCmsPage(req, session, CmsPageIdentifier.LandingPage);
    const landingPageEnabled = landingPage?.state === 'published';
    return {
        props: {
            ...pageData,
            sharedBet,
            sharedBetItems: sharedBetItems ? JSON.parse(JSON.stringify(sharedBetItems)) : null,
            landingPageEnabled,
        },
    };
}, CmsPageIdentifier.FrontPage, [PageFilterType.PageRequiresAuthentication]);
export default Home;

    async function __Next_Translate__getServerSideProps__19205d70865__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/sports/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__19205d70865__ as getServerSideProps }
  