import { CompetitionFlags } from '@arland-bmnext/api-data'
import { CasinoCategoriesDTO, CmsCompetitionDTO } from '@arland-bmnext/webapps-api-data'
import { CasinoCategoryType } from '@bmnext-cms/types/casino'
import { Translate } from 'next-translate'

export const getCasinoGameImgIdentifier = (casinoGame: CmsCompetitionDTO, ratio?: '1x1' | '4x1' | '3x4' | '4x3') => {
  const customImage = ratio != null ? casinoGame?.resources?.find((r) => r.ratio === ratio) : null
  if (customImage) return customImage.fileUrl

  const split = casinoGame.identifier.split('_')
  const provider = split[0]
  const gameIdentifier = split[1]

  if (
    ![
      'bmg-sc',
      'boom',
      'evo-lc',
      'th-sc',
      'xpress-hwtv',
      'xpress-sm',
      'xpress-tvb',
      'spr-g',
      'salsa-prag',
      'salsa-spinom',
      'salsa-pgs',
      'salsa-rrg',
    ].includes(provider)
  ) {
    return null
  }

  return `/casino/${provider}/${gameIdentifier}_prv.webp`
}

export const getRatioForLayout = (layout: 'grid' | 'cards' | 'slider') => {
  switch (layout) {
    case 'grid':
      return '4x3'
    case 'cards':
      return '3x4'
    case 'slider':
      return '4x1'
    default:
      return '4x3'
  }
}

export type CasinoCategoryViewModel = {
  id: number
  name: string
  type: CasinoCategoryType
  icon: string
  displayMode: string
  games: CmsCompetitionDTO[]
}

const getNameForCasinoCategory = (t: Translate, casinoCategory: CasinoCategoriesDTO, languageId: number): string => {
  let name = ''
  switch (casinoCategory.categoryType as CasinoCategoryType) {
    case 'highlights':
      name = t('Casino.categories.highlights')
      break
    case 'recently-played':
      name = t('Casino.categories.recentlyPlayed')
      break
    case 'popular':
      name = t('Casino.categories.popular')
      break
    case 'custom':
      name = casinoCategory.name
      break
  }
  return name
}

const getGamesForCasinoCategory = (
  casinoCategory: CasinoCategoriesDTO,
  casinoGames: CmsCompetitionDTO[],
  recentlyPlayedGames: number[],
): CmsCompetitionDTO[] => {
  let games = []
  if ((casinoCategory.categoryType as CasinoCategoryType) === 'recently-played') {
    recentlyPlayedGames.forEach((gameId) => {
      const casinoGame = casinoGames?.find((cg) => cg.id === gameId)
      if (casinoGame) games.push(casinoGame)
    })
  } else {
    games = casinoCategory.competitions
  }
  if (casinoCategory.maxEntries != null) return games.slice(0, casinoCategory.maxEntries)
  return games
}

export const buildCasinoCategoriesViewModel = (
  t: Translate,
  casinoCategories: CasinoCategoriesDTO[],
  casinoGames: CmsCompetitionDTO[],
  recentlyPlayedGames: number[],
  languageId: number,
): CasinoCategoryViewModel[] => {
  const res: CasinoCategoryViewModel[] = []
  casinoCategories?.forEach((category) => {
    const iconSplit = category.icon?.split(' ')

    const c: CasinoCategoryViewModel = {
      id: category.id,
      name: getNameForCasinoCategory(t, category, languageId),
      type: category.categoryType as CasinoCategoryType,
      icon: category.icon ? (iconSplit[1] ? iconSplit[1] : iconSplit[0]) : null,
      displayMode: category.displayMode ?? 'grid',
      games: getGamesForCasinoCategory(category, casinoGames, recentlyPlayedGames),
    }

    if (c.games?.length > 0) res.push(c)
  })

  return res
}

export const isDemoModeAvailable = (casinoGame: CmsCompetitionDTO): boolean => {
  return (casinoGame.flags & CompetitionFlags.HasDemoMode) === CompetitionFlags.HasDemoMode
}
