import { ConditionalWrapper } from '../core/ConditionalWrapper'

export type BaseWidgetProps = {
  index?: number
  title?: string
  actionElement?: any
  description?: string
  addPadding?: boolean
  addTopBorder?: boolean
  children?: any
  className?: string
  boxedContent?: boolean
  childrenOnly?: boolean
}

const BaseWidget = ({
  title,
  actionElement,
  description,
  addPadding = false,
  addTopBorder = false,
  children,
  className = '',
  boxedContent = false,
  childrenOnly = false,
}: BaseWidgetProps) => {
  return (
    <ConditionalWrapper
      condition={!childrenOnly}
      wrapper={(children) => (
        <div className={`base-widget flex flex-col space-y-2 flex-shrink-0 overflow-hidden ${className}`}>
          {children}
        </div>
      )}
    >
      {addTopBorder && !childrenOnly && (
        <div className="base-widget-top-border-wrapper flex md:hidden pl-4 xl:pl-0">
          <div className="base-widget-top-border w-full border-t-[.5px] border-neutral"></div>
        </div>
      )}

      {(title || description) && !childrenOnly && (
        <div className="base-widget-info flex flex-col space-y-[2px]">
          {title && (
            <div className={`flex justify-between items-center space-x-4 ${addPadding ? 'px-3 md:px-4' : ''}`}>
              <span className="base-widget-info-title text-xl font-bold">{title}</span>
              {actionElement && <div className="base-widget-info-action flex flex-shrink-0">{actionElement}</div>}
            </div>
          )}

          {description && (
            <div className={`base-widget-info-description-wrapper flex ${addPadding ? 'px-3 md:px-4' : ''}`}>
              <span className="base-widget-info-description text-sm opacity-70">{description}</span>
            </div>
          )}
        </div>
      )}

      {children && (
        <ConditionalWrapper
          condition={!childrenOnly}
          wrapper={(children) => (
            <div
              className={`base-widget-content ${
                boxedContent
                  ? 'base-widget-content-boxed mx-2 md:mx-4 rounded-md overflow-hidden border-[1px] border-neutral bg-card text-cardContrast'
                  : ''
              }`}
            >
              {children}
            </div>
          )}
        >
          {children}
        </ConditionalWrapper>
      )}
    </ConditionalWrapper>
  )
}

export default BaseWidget
