import React, { useMemo } from 'react'
import { useCasinoSettings } from '../../lib/content'
import { isAnonymousUser, isLoggedInCustomer, useUser } from '../../lib/user'
import BaseWidget, { BaseWidgetProps } from './BaseWidget'
import Image from 'next/legacy/image'
import { getCasinoGameImgIdentifier } from '../../util/gaming'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useCasinoGameViewModel, useRecentlyPlayedCasinoGames } from '../../lib/gaming'
import { buildCasinoGameUrl } from '../../util/url'
import { CmsCompetitionDTO } from '@arland-bmnext/webapps-api-data'

type CasinoGamesWidgetProps = BaseWidgetProps & {
  displayRecentlyPlayedGames?: boolean
  grid_side_length?: number
}

const CasinoGamesWidget = (props: CasinoGamesWidgetProps) => {
  const router = useRouter()
  const { t } = useTranslation('bets')
  const { user } = useUser()
  const casinoSettings = useCasinoSettings()

  const [availableCasinoGames, casinoCategoriesViewModel] = useCasinoGameViewModel()
  const [recentlyPlayedGames] = useRecentlyPlayedCasinoGames()

  const gridSize = props.grid_side_length ?? 3
  const gamesCount = gridSize * gridSize
  const gamesToDisplay = useMemo(() => {
    let games = []

    if (availableCasinoGames == null || availableCasinoGames.length === 0) return games

    if (props.displayRecentlyPlayedGames) {
      const recentlyPlayedGames = casinoCategoriesViewModel?.find((c) => c.type === 'recently-played')?.games
      recentlyPlayedGames?.slice(0, Math.min(gamesCount, recentlyPlayedGames.length))?.forEach((game) => {
        games.push(game)
      })
    }

    if (games.length < gamesCount) {
      const highlightGames = casinoCategoriesViewModel?.find((c) => c.type === 'popular')?.games
      const gamesToFill = highlightGames?.filter((h) => games.find((g) => g.id === h.id) == null)
      if (gamesToFill) games.push(...gamesToFill.slice(0, Math.min(gamesCount - games.length, gamesToFill.length)))
    }

    if (games.length < gamesCount) {
      const gamesToFill = availableCasinoGames?.filter((a) => games.find((g) => g.id === a.id) == null)
      if (gamesToFill) games.push(...gamesToFill.slice(0, Math.min(gamesCount - games.length, gamesToFill.length)))
    }

    return games
  }, [recentlyPlayedGames, availableCasinoGames, casinoCategoriesViewModel])

  const onPlayCasinoGame = async (gameId: number, gameName: string): Promise<void> => {
    router.push(buildCasinoGameUrl(gameId, gameName))
  }

  const GoToCasinoPage = () => {
    return (
      <Link href="/casino" legacyBehavior>
        <span className="text-primary text-sm font-medium cursor-pointer hover:underline">
          {t('CasinoGamesWidget.showAll')}
        </span>
      </Link>
    )
  }

  return (
    <>
      {casinoSettings?.enabled &&
        availableCasinoGames?.length > 0 &&
        ((isLoggedInCustomer(user) && user?.betPlacementAndGamingAllowed) ||
          (isAnonymousUser(user) && casinoSettings.visibleToAnonymous)) && (
          <BaseWidget
            title={props.title ? props.title : t('CasinoGamesWidget.title')}
            actionElement={<GoToCasinoPage />}
            {...props}
          >
            <div
              className={`casino-games-widget grid gap-1 p-2 bg-card rounded-md ${
                props.addPadding ? 'mx-2 md:mx-4' : ''
              }`}
              style={{
                gridTemplateColumns: `repeat(${gridSize}, minmax(0, 1fr))`,
                gridTemplateRows: `repeat(${gridSize}, minmax(0, 1fr))`,
              }}
            >
              {gamesToDisplay?.map((casinoGame: CmsCompetitionDTO) => {
                const imgIdentifier = getCasinoGameImgIdentifier(casinoGame, '4x3')
                return (
                  <div
                    className="casino-games-widget-item rounded-[4px] aspect-[4/3] w-full h-full overflow-hidden relative cursor-pointer"
                    onClick={() => onPlayCasinoGame(casinoGame.id, casinoGame.name)}
                    key={casinoGame.id}
                  >
                    {imgIdentifier ? (
                      <Image
                        src={imgIdentifier}
                        layout="fill"
                        objectFit="cover"
                        alt={casinoGame.name}
                        className={'casino-games-widget-item-img transition'}
                        placeholder="blur"
                        blurDataURL={imgIdentifier}
                      />
                    ) : (
                      <div className="casino-games-widget-item-name flex justify-center items-center h-full w-full bg-gray-500 text-white">
                        {casinoGame.name}
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </BaseWidget>
        )}
    </>
  )
}

export default CasinoGamesWidget
